export const specificationImages = [
    {
        size: 'big',
        backgroundImage: '/icon/big/png%20tiny/Beauty%20and%20health.png',
        name: "Beauty and Health",
    },
    {
        size: 'big',
        backgroundImage: '/icon/big/png%20tiny/Cleaning%20services.png',
        name: "Cleaning Services",
    },
    {
        size: 'small',
        backgroundImage: '/icon/big/png%20tiny/Real%20estate%20services.png',
        name: "Real Estate Services",
    },
    {
        size: 'small',
        backgroundImage: '/icon/big/png%20tiny/Car%20rental%20services.png',
        name: "Car Rental Services",
    },
    {
        size: 'small',
        backgroundImage: '/icon/big/png%20tiny/Events%20and%20Promotions.png',
        name: "Events and Promotions",
    },
    {
        size: 'big',
        backgroundImage: '/icon/big/png%20tiny/Home%20staff.png',
        name: "Home Staff",
    },
    {
        size: 'big',
        backgroundImage: '/icon/big/png%20tiny/Repair%20services%20of%20equipment.png',
        name: "Repair Services of Equipment",
    }
]